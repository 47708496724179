import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import EmbedPlayer from '../../components/EmbedPlayer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`This example demonstrates how you can build a custom UI based on our API calls.`}</h3>
    <p>{`There are some important technical information about a custom UI implementation:`}</p>
    <ul>
      <li parentName="ul">{`there is no progress event from the player, so you should query the progress periodically (using the `}<inlineCode parentName="li">{`playing`}</inlineCode>{` event to disable unnecessary querying will increase performance)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`progress`}</inlineCode>{` and `}<inlineCode parentName="li">{`duration`}</inlineCode>{` is in seconds, so every percentage related progress data (like a seekbar) should be calculated from this two data`}</li>
      <li parentName="ul">{`if you would like to build a full custom UI on the player, use the `}<inlineCode parentName="li">{`controls=false&showtitle=false`}</inlineCode>{` URL params to hide the default IBM Video Streaming UI components`}</li>
      <li parentName="ul">{`autoplay permission must be delegated on cross-origin iframes since Chrome 66 for video elements by policy. Therefore, the iframe tag must contain an `}<inlineCode parentName="li">{`allow="autoplay"`}</inlineCode>{` feature policy attribute to allow play calls from a cross-orgin parent to the player iframe. For more information visit `}<a parentName="li" {...{
          "href": "https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#iframe"
        }}>{`https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#iframe`}</a></li>
    </ul>
    <h3>{`Example`}</h3>

    <EmbedPlayer src="https://video.ibm.com/embed/recorded/81056340?volume=0" controls={['custom']} mdxType="EmbedPlayer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      